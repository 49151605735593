import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Message from "./Message";

function Redirect({ integrationName, timeout }) {
  Redirect.propTypes = {
    integrationName: PropTypes.string.isRequired,
    timeout: PropTypes.number.isRequired,
  };

  const [queryParams] = useSearchParams();

  const [bodyData, setBodyData] = useState({
    msgHeading: "Redirecting...",
    msgBody: "You will be redirected to the requested page shortly.",
  });

  // log search params
  console.info("INTEGRATION:", integrationName);
  queryParams.forEach((value, key) => {
    console.log(`${key}=${value}`);
  });

  useEffect(() => {
    // using state for redirects
    let redirectURL = queryParams.get("state");

    // check validity
    if (!redirectURL) {
      console.error("No redirect URL found");

      setBodyData({
        msgHeading: "Redirect Error",
        msgBody: "No redirect URL found. Please contact support.",
        msgBtn: "Contact Support",
        msgLink: "https://www.userful.com/support",
      });

      return;
    }

    // check protocol
    if (!redirectURL.startsWith("http://") && !redirectURL.startsWith("https://")) {
      console.warn("Redirect URL is missing protocol, assuming 'http://'");
      redirectURL = "http://" + redirectURL;
    }

    // append query params
    const queryString = queryParams.toString();
    if (queryString) {
      redirectURL += "?" + queryString;
    }

    // append integration name
    redirectURL += `&integration=${integrationName}`;

    setTimeout(() => {
      console.log("Redirecting to:", redirectURL);
      window.location.assign(redirectURL);
    }, timeout);
  }, [queryParams, timeout, integrationName]);

  return <Message {...bodyData} />;
}

export default Redirect;
