import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";

function Footer() {
  return (
    <Navbar className="userful-bottom-navbar" bg="dark" variant="dark" data-bs-theme="dark" expand="sm" fixed="bottom">
      <Container className="userful-container">
        <Nav className="">
          <Nav.Item>
            <Nav.Link rel="noreferrer" style={{ cursor: "text" }}>
              Copyright © 2023 Userful Corporation. All rights reserved.
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="https://docs.userful.com/terms-and-conditions/privacy-policy" target="_blank" rel="noreferrer">
              Privacy Policy
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="https://docs.userful.com/terms-and-conditions/diversity-and-inclusion-policy" target="_blank" rel="noreferrer">
              Diversity & Inclusion Policy
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default Footer;
