import PropTypes from "prop-types";
import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";

function Message({ msgHeading, msgBody, msgBtn, msgLink }) {
  Message.propTypes = {
    msgHeading: PropTypes.string.isRequired,
    msgBody: PropTypes.string.isRequired,
    msgBtn: PropTypes.string.isRequired,
    msgLink: PropTypes.string.isRequired,
  };

  return (
    <Container className="d-flex justify-content-center align-items-center userful-container userful-body">
      <Row>
        <Col>
          <Card className="userful-card">
            <Card.Body className="userful-card-body">
              {msgHeading && <Card.Title className="userful-card-title">{msgHeading}</Card.Title>}
              {msgBody && <Card.Text className="userful-card-text">{msgBody} </Card.Text>}
              {msgBtn && (
                <Button variant="dark" className="userful-card-button" href={msgLink}>
                  {msgBtn}
                </Button>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Message;
