import React from "react";
import { Container, Navbar } from "react-bootstrap";

function Header() {
  return (
    <Navbar className="userful-navbar" bg="dark" variant="dark" data-bs-theme="dark" expand="sm" collapseOnSelect>
      <Container className="userful-container">
        <Navbar.Brand href="/" className="fw-bold">
          <img alt="userful" src="https://www.userful.com/hubfs/Logo%202023/Secondary%20Logo/userful-secondary-light.svg" width="175" height="35" />
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}

export default Header;
