import PropTypes from "prop-types";
import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Redirect from "../components/Redirect";

function Integration({ timeout, integrationName }) {
  Integration.propTypes = {
    timeout: PropTypes.number.isRequired,
    integrationName: PropTypes.string.isRequired,
  };

  return (
    <div>
      <Header />
      <Redirect timeout={timeout} integrationName={integrationName} />
      <Footer />
    </div>
  );
}

export default Integration;
