import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Message from "../components/Message";

function ErrorPage() {
  const bodyData = {
    msgHeading: "404 Page not Found",
    msgBody: "The page you are looking for doesn't exist or has been moved",
    msgBtn: "Return to Home",
    msgLink: "https://www.userful.com",
  };

  return (
    <React.Fragment>
      <Header />
      <Message {...bodyData} />
      <Footer />
    </React.Fragment>
  );
}

export default ErrorPage;
